// You can directly change all links in the footer here

const FooterLinks = {
    FAQ: "", // FAQ
    TNC: "", // Terms & Conditions
    HELP_CENTER: "", // Help Center
    CONTACT_US: "", // Contact Us
    TOS: "", // Terms Of Service
    PRIVACY: "", // Privacy
    CORPORATE_INFO: "", // Corporate Information
    LEGAL_NOTICE: "", // Legal Notice
}

export default FooterLinks;