import React from "react";
import styled from "styled-components";
import { Link as ScrollTo } from "react-scroll";

// Images
// Logo Image
import Logo from "../images/logo.png";
// Hero/Home Image
import HeroImage from "../images/hero.png";

// Styled-components
const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 768px) {
    background-repeat: no-repeat;
    background-position: center;
    min-height: 110vh;
    height: -webkit-fill-available;
  }
`

const DataContainer = styled.div`
  // Animation for Fade-In effect
  animation: fadeInAnimation ease 1s forwards; 
  @keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
  } 

  margin-top: 200px;
  height: 550px;
  width: 470px;
  @media (max-width: 1600px) {
    width: 500px;
  }
  @media (max-width: 1400px) {
    width: 370px;
  }
  @media (max-width: 968px) {
    width: 300px;
  }
  @media (max-width: 768px) {
    height: 500px;
    width: 250px;
    margin-top: 0;
  }
`

const Title = styled.div`
  color: #fff;
  cursor: default;
  font-weight: bold;
  font-size: 3rem;
  @media (max-width: 1400px) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 2.4rem;
  }
`

const Subtitle = styled.div`
  color: #ddd;
  font-size: 1.2rem;
  cursor: default;
  padding-bottom: 0.5rem;
  font-weight: 600;
  letter-spacing: 0.2;
  @media (max-width: 1400px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`

const Description = styled.div`
  color: #ccc;
  font-size: 1.1rem;
  padding-bottom: 1rem;
  cursor: default;
  letter-spacing: 0.2;
  @media (max-width: 1400px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
`

const Button = styled.div`
  color: #ccc;
  border: 1px #ccc solid;
  background-color: #000;
  padding: 5px 10px;
  font-size: 1.1rem;
  :hover {
    background-color: #FF2323;
    border: 1px #FF2323 solid;
  }
  transition: all ease 300ms;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 1400px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

const ImageContainer = styled.img`
  width: 800px;
  height: 700px;
  @media (max-width: 1600px) {
    width: 800px;
    height: 700px;
  }
  @media (max-width: 1400px) {
    width: 660px;
    height: 540px;
  }
  @media (max-width: 968px) {
    width: 500px;
    height: 400px;
  }
  @media (max-width: 768px) {
    width: 0;
    height: 0;
  }
`

const LogoContainer = styled.img`
  width: 300px;
  height: 120px;
  @media (max-width: 768px) {
    width: 280px;
    height: 120px;
  }
` 

const Hero = () => {
  // The id "home" is required for navigation
  return (
    <HeroContainer id="home"> 
      <DataContainer>
        <Title>
          <LogoContainer src={Logo} />
        </Title>
        <Subtitle>
        LIVE EVENTS GET LIVELIER WITH LIVELY
        </Subtitle>
        <Description>
        By combining event ticketing and live streaming Lively.TV is pioneering a new level of access to events. 
        </Description>
        <ButtonContainer>
          <Button>
            <ScrollTo to="services" duration={1000} smooth={true} offset={-60} >
              Learn More
            </ScrollTo>
          </Button>
        </ButtonContainer>
      </DataContainer>
      <ImageContainer src={HeroImage} />
    </HeroContainer>
  )
}

export default Hero

