import React from 'react';
import './App.css';

// Components
import Header from "./components/header";
import Hero from "./components/hero";
import Services from "./components/services";
import WhyUs from "./components/why-us";
import Pricing from "./components/pricing";
import Footer from "./components/footer";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Services />
      <WhyUs />
      <Pricing />
      <Footer />
    </div>
  );
}

export default App;
