import React from "react"
import styled from "styled-components"

// Importing footer links from "footer-links.js"
import FooterLinks from "../data/footer-links";

// Styled-components
const FooterContainer = styled.div`
  background: #000;
  padding: 1rem 0;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
`

const TextRow = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 150px;
  }
`

const Link = styled.a`
  color: #aaa;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  :hover {
    color: #fff;
  }
`

const Text = styled.div`
  color: #aaa;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  cursor: default;
`

const Copyright = styled.div`
  font-size: 1rem;
  color: #aaa;
  cursor: default;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <TextContainer>
        <TextRow></TextRow>
        <TextRow>
          <Link href={FooterLinks.FAQ}>FAQ</Link>
          <Link href={FooterLinks.TNC}>Terms & Conditions</Link>
          <Link href={FooterLinks.HELP_CENTER}>Help Center</Link>
          <Link href={FooterLinks.CONTACT_US}>Contact Us</Link>
        </TextRow>
        <TextRow></TextRow>
        <TextRow>
          <Link href={FooterLinks.TOS}>Terms Of Service</Link>
          <Link href={FooterLinks.PRIVACY}>Privacy</Link>
          <Link href={FooterLinks.CORPORATE_INFO}>Corporate Information</Link>
          <Link href={FooterLinks.LEGAL_NOTICE}>Legal Notice</Link>
        </TextRow>
        <TextRow></TextRow>
        <TextRow>
          <Text>Questions?</Text>
          <Text>Contact us admin@lively.tv</Text>
        </TextRow>
        <TextRow></TextRow>
      </TextContainer>
      <Copyright>
        Copyright © 2020 | LivelyTV
      </Copyright>
    </FooterContainer>
  )
}

export default Footer
