import React from "react"
import styled from "styled-components"

// Images
import Tick from "../images/tick.png";
import Cross from "../images/cross.jpg";

// Styled-components
const WhyUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
  }
  @media only screen and (max-width: 1268px) {
    width: 100%;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: relative;
    
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  position: relative;
  color: #fff;
  position: absolute;
  top: 4rem;
  @media only screen and (max-width: 1268px) {
    top: 6rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  
  position: relative;
  @media only screen and (max-width: 1268px) {
    padding: 10rem 0 2rem 0;
  }
`

const TextRow = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  @media only screen and (max-width: 1268px) {
    width: 60px;
    padding 1rem 0;
  }
  padding: 1rem;
`

const EmptyTextRow = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media only screen and (max-width: 1268px) {
    width: 20px;
    padding 1rem 0;
  }
`

const LeftTextRow = styled.div`
  width: 150px;
  display: flex;
  text-align: right;
  flex-direction: column;
  padding-bottom: 1rem;
  @media only screen and (max-width: 1268px) {
    width: 100px;
    padding 1rem 0;
  }
  padding: 1rem;
`

const LivelyTVTextRow = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 1268px) {
    width: 60px;
    padding 1rem 0;
  }
  padding: 1rem;
  background: #212121;
` 

const Text = styled.div`
  color: #fff;
  @media only screen and (max-width: 1268px) {
    font-size: 0.84rem;
  }
  padding-bottom: 1.4rem;
`

const RedText = styled.div`
  color: #FF2323;
`

const AlignedText = styled.div`
  padding-bottom: 0.15rem;
  @media only screen and (max-width: 1268px) {
    padding-bottom: 0;
  }
`

const HeaderText = styled(Text)`
  font-size: 1.2rem;
  @media only screen and (max-width: 1268px) {
    font-size: 0.8rem;
  }
`

const HiddenText = styled(HeaderText)`
  color: #000;
`

const Hidden = styled.div`
  display: none;
  @media (max-width: 1268px) {
    display: block;
    color: #212121;
  }
  
`

const HiddenBlack = styled.div`
  display: none;
  @media (max-width: 1268px) {
    display: block;
    color: #000;
  }
  
`

const TickImg = styled.div`
  background: url(${Tick});
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  @media (max-width: 1268px) {
    width: 20px;
    height: 20px;
  }
` 

const WrongImg = styled.div`
  background: url(${Cross});
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  @media (max-width: 1268px) {
    width: 20px;
    height: 20px;
  }
` 

const WhyUs = () => {
    // The id "why-us" is required for navigation
    return (
      <div>
      <WhyUsContainer id="why-us">
      <Title>Why Us?</Title>
      <TextContainer>
        <EmptyTextRow></EmptyTextRow>
        <LeftTextRow>
          <HiddenText>a<HiddenBlack>aaa</HiddenBlack></HiddenText>
          <Text><AlignedText>Privacy</AlignedText></Text>
          <Text><AlignedText>Security</AlignedText></Text>
          <Text><AlignedText>Monetization</AlignedText></Text>
          <Text><AlignedText>Social Features</AlignedText></Text>
          <Text><AlignedText>Watch Together</AlignedText></Text>
          <Text><AlignedText>Multiple views</AlignedText></Text>
          <Text><AlignedText>Multiple rooms</AlignedText></Text>
          <Text><AlignedText>Match making</AlignedText></Text>
        </LeftTextRow>
        <EmptyTextRow></EmptyTextRow>
        <LivelyTVTextRow>
          <HeaderText><RedText>LivelyTV</RedText><Hidden>aaa</Hidden></HeaderText>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
        </LivelyTVTextRow>
        <EmptyTextRow></EmptyTextRow>
        <TextRow>
          <HeaderText>Facebook Live</HeaderText>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
          <Text><TickImg /></Text>
          <Text><WrongImg /></Text>
        </TextRow>
        <EmptyTextRow></EmptyTextRow>
        <TextRow>
          <HeaderText>Youtube Live</HeaderText>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
          <Text><TickImg /></Text>
          <Text><TickImg /></Text>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
          <Text><WrongImg /></Text>
        </TextRow>
        <EmptyTextRow></EmptyTextRow>
      </TextContainer>
      </WhyUsContainer>
      </div>
    )
}

export default WhyUs
