import React from "react"
import styled from "styled-components"

// Images
// Background
import PricingImg from "../images/pricing.jpg";
// Tick Icon
import Tick from "../images/tick.png";

// Styled-components
const PricingContainer = styled.div`
  background: url(${PricingImg});
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
  }
  @media only screen and (max-width: 768px) {
    background: url(${PricingImg});
    width: 100%;
    height: 1400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: relative;
    
  }
`

const Title = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 2rem;
  position: relative;
  color: #fff;
  position: absolute;
  top: 8rem;
  @media only screen and (max-width: 768px) {
    top: 6rem;
  }
`


const HeadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 220px;
  @media only screen and (max-width: 768px) {
    width: 250px;
  }
  border-bottom: 1px solid #777;
`

const Heading = styled.div`
  color: #fff;
  padding: 1rem 0;
`

const MiddleHeading = styled.div`
  color: #FF2323;
  padding: 1rem 0;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: block;
    padding: 10rem 0 2rem 0;
  }
`

const InnerTextContainer = styled.div`
  padding: 0 1rem;
`

const TextRow = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 250px;
    padding 1rem 0;
  }
`
const SmallerTextRow = styled.div`
  width: 40px;
  @media only screen and (max-width: 768px) {
    width: 250px;
    padding 1rem 0;
  }
`

const TextRowDark = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media only screen and (max-width: 768px) {
    width: 250px;
    padding 1rem 0;
  }
  border: 1px solid #777;
  background: #000;
`

const Text = styled.div`
  color: #fff;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  padding-bottom: 0.5rem;
  padding-left: 0.2rem;
  display: flex;
  align-items: center;
`

const InnerText = styled.div`
  padding-left: 1rem;
`

const SecondLineText = styled.div`
  color: #fff;
  padding-left: 38px;
  padding-bottom: 0.4rem;
`

const LeftPrice = styled.div`
  color: #fff;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  display: flex;
  justify-content: center;
  position: relative;
  top: 62px;
  @media only screen and (max-width: 768px) {
    top: 1px;
  }
  padding-top: 6rem;
`

const RightPrice = styled.div`
  color: #fff;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  display: flex;
  justify-content: center;
  padding-top: 6rem;
`

const MiddlePrice = styled.div`
  color: #fff;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  display: flex;
  justify-content: center;
  padding-top: 6rem;
`

const TickImg = styled.div`
  background: url(${Tick});
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
` 

const Pricing = () => {
  // The id "pricing" is required for navigation
    return (
      <div>
      <PricingContainer id="pricing">
      <Title>Pricing Plans</Title>
      <TextContainer>

        <TextRow></TextRow>

        <TextRowDark>
          <HeadingContainer>
            <Heading>STANDARD</Heading>
          </HeadingContainer>
          <InnerTextContainer>
            <Text><TickImg/><InnerText>Upto 33 hours Live</InnerText></Text>
            <Text><TickImg/><InnerText>Link Sharing</InnerText></Text>
            <LeftPrice>$4.99</LeftPrice>
          </InnerTextContainer>
        </TextRowDark>

        <SmallerTextRow></SmallerTextRow>

        <TextRowDark>
          <HeadingContainer>
            <MiddleHeading>PREMIUM</MiddleHeading>
          </HeadingContainer>
          <InnerTextContainer>
            <Text><TickImg/><InnerText>White Label</InnerText></Text>
            <Text><TickImg/><InnerText>Unlimited viewing</InnerText></Text>
            <Text><TickImg/><InnerText>Channel Package</InnerText></Text> <SecondLineText>deals</SecondLineText>
            <MiddlePrice>$49.99</MiddlePrice>
          </InnerTextContainer>
        </TextRowDark>

        <SmallerTextRow></SmallerTextRow>

        <TextRowDark>
          <HeadingContainer>
            <Heading>ENTERPRISE</Heading>
          </HeadingContainer>
          <InnerTextContainer>
            <Text><TickImg/><InnerText>White Label</InnerText></Text>
            <Text><TickImg/><InnerText>HLS live & Multi</InnerText></Text> <SecondLineText>-cam HD</SecondLineText>
            <Text><TickImg/><InnerText>Create own event</InnerText></Text>
            <RightPrice>$xx.xx</RightPrice>
          </InnerTextContainer>
        </TextRowDark>

        <TextRow></TextRow>

      </TextContainer>
      </PricingContainer>
      </div>
    )
}

export default Pricing
