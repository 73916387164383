import React from "react"
import styled from "styled-components"

// Images
// Background
import ServicesBg from "../images/services.png";
// Features
import Feature1 from "../images/feature1.png";
import Feature2 from "../images/feature2.png";
import Feature3 from "../images/feature3.png";

// Styled-components
const ServicesContainer = styled.div`
  background: url(${ServicesBg});
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
  }
  @media (min-width: 769px)and (max-width: 1268px) {
    width: 100%;
    height: 1200px;
    padding 1rem 0;
  }
  @media only screen and (max-width: 768px) {
    background: url(${ServicesBg});
    width: 100%;
    height: 1200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // position: relative;
    
  }
`

const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  position: relative;
  color: #fff;
  position: absolute;
  top: 8rem;
  @media only screen and (max-width: 768px) {
    top: 6rem;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`

const Image = styled.img`
  width: 110px;
  height: 100px;
`

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  
  position: relative;
  @media only screen and (max-width: 1268px) {
    display: block;
    padding: 10rem 0 2rem 0;
  }
`

const TextRow = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  @media (min-width: 769px)and (max-width: 1268px) {
    width: 200px;
    padding 1rem 0;
  }
  @media only screen and (max-width: 768px) {
    width: 250px;
    padding 1rem 0;
  }
  
`

const Text = styled.div`
  color: #fff;
  text-decoration: none;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
`

const Services = () => {
    // The id "servicesq" is required for navigation
    return (
      <div>
      <ServicesContainer id="services">
      <Title>Our Services</Title>
      <TextContainer>
        <TextRow></TextRow>
        <TextRow>
          <ImageContainer>
            <Image src={Feature1} />
          </ImageContainer>
          <Text>Browse through events, channels, and rooms, and join or match with people with the same interests</Text>
        </TextRow>
        <TextRow></TextRow>
        <TextRow>
        <ImageContainer>
            <Image src={Feature2} />
          </ImageContainer>
          <Text>Buy Tickets and Subscribe, and create or join a room, and watch together with your new matches or friends, the same event.</Text>
        </TextRow>
        <TextRow></TextRow>
        <TextRow>
        <ImageContainer>
            <Image src={Feature3} />
          </ImageContainer>
          <Text>Enjoy Lively TV’s state of the Art,Live Video Player, with Multiple rooms, multiple Views, In-Chat, and Watch Together features.</Text>
        </TextRow>
        <TextRow></TextRow>
      </TextContainer>
      </ServicesContainer>
      </div>
    )
}

export default Services
