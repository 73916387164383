// You can change navigation settings here
// Title: Name in the UI
// URL: This string and the ID string in each section must match
// OffsetVal: This is how much you would want the screen to show after scrolling

const NavigationLinks = [
    {
        title: "Home",
        url: "home",
        offsetVal: -500, 
    },
    {
        title: "Services",
        url: "services",
        offsetVal: -130, 
    },
    {
        title: "Why Us?",
        url: "why-us",
        offsetVal: -60, 
    },
    {
        title: "Pricing",
        url: "pricing",
        offsetVal: -100, 
    }
]

export default NavigationLinks;