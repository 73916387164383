import React, { useState } from "react"
import styled from "styled-components"
import { Link as ScrollTo } from "react-scroll"

// Data for navigation pulled from data/nagivation-links.js
import navigationLinks from "../data/navigation-links"

// Images
// Logo
import Logo from "../images/logo.png";

// Styled-components
const Navigation = styled.nav`
  height: 12vh;
  display: flex;
  background-color: #000;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10vw;
  z-index: 2;
  align-self: center;
  position: fixed;
  // position: relative; // If you want the header to be stuck to the top
  width: 80%;
  @media (max-width: 768px) {
    position: fixed;
    height: 8vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    -webkit-filter: none;
    filter: none;
  }
`

const Title = styled.div`
font-size: 1.5rem;
text-decoration: none;
color: #fff;
font-weight: 800;
display: inline-block;
white-space: nowrap;
margin: 0 1vw;
transition: all 300ms ease-in;
position: relative;
font-family: "Poppins";
cursor: pointer;
}
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 5vw;
  @media (max-width: 768px) {
    display: flex;
  }
`

const TitleContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
`

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #000;
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const NavItem = styled.span`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 300ms ease-in;
  position: relative;
  font-family: "Poppins";
  font-size: 1.1rem;
  cursor: pointer;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #FF2323;
    height: 3px;
    transition: all 0.2s ease-in-out;
  }

  :hover {
    opacity: 1;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.2rem;
    z-index: 6;
    :hover {
      opacity: 1;
      ::after {
        width: 100%;
      }
    }
  }
`

const LogoContainer = styled.img`
  width: 180px;
  height: 70px;
  @media (max-width: 768px) {
    width: 120px;
    height: 50px;
  }
` 

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
      <TitleContainer>
        <Title>
          <ScrollTo to="home" smooth={true} duration={1000} offset={-500} >
            <LogoContainer src={Logo} />
          </ScrollTo>
        </Title>
      </TitleContainer>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        // Mobile mode
        <Navbox>
          {navigationLinks.map((link, i) => (
            <>
              <ScrollTo key={i} to={link.url} duration={1000} smooth={true} offset={link.offsetVal} >
                <NavItem key={i} onClick={() => setNavbarOpen(!navbarOpen)}>
                  {link.title}
                </NavItem>
              </ScrollTo>
            </>
          ))}
        </Navbox>

      ) : (
          // Desktop mode
          <Navbox open>
            {navigationLinks.map((link, i) => (
              <>
                <NavItem key={i} onClick={() => setNavbarOpen(!navbarOpen)}>
                  <ScrollTo key={i} to={link.url} duration={1000} smooth={true} offset={link.offsetVal} >
                    {link.title}
                  </ScrollTo>
                </NavItem>
              </>
            ))}
          </Navbox>
        )}
    </Navigation>
  )
}

export default Header